


























import { Component } from 'vue-property-decorator';
import NestedNavComponent from '../components/NestedNavComponent.vue';
import ProjectHeadingComponent from '../components/ProjectHeadingComponent.vue';
import BaseComponent from '../components/BaseComponent';
import { MenuMeta } from '../store/models';

@Component({
  components: {
    NestedNavComponent,
    ProjectHeadingComponent,
  },
})
export default class ProjectSummaryNav extends BaseComponent {

  protected baseRoute: string = '/projects/id/';

  private refreshHeader: number = 0;

  private nestedMenu: MenuMeta[] = [
                {
                  title: 'Insights',
                  href: '',
                },
                {
                  title: 'Details',
                  href: '/details',
                },
                {
                  title: 'Payees',
                  href: '/payees',
                },
          ];

  private projectActions: any[] = [
    { label: 'Payee', code: 'project-payee' },
    { label: 'Expense', code: 'project-payee-expense' },
  ];

  protected mounted() {
    this.baseRoute += this.$route.params.id;
    if (this.isFeatureEnabled(this.features.employmentMenu)) {
      this.nestedMenu.push({ title: 'Employment', href: '/employment' });
    }
  }

  private navigate(code) {
    switch (code) {
      case 'project-payee':
        this.changeRoute('/projects/id/' + this.$route.params.id + '/payees/new-payee', true);
        break;
      case 'project-payee-expense':
        this.changeRoute('/projects/id/' + this.$route.params.id + '/payees/new-expense', true);
        break;
    }
  }

  private getReportLink(): string {
    return '/reports/social-impact-project?project=' + this.$route.params.id;
  }
}
