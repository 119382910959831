














import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import { getProject } from '../store/rest.service';
import { Project } from '../store/models';
import * as _ from 'lodash';

@Component
export default class ProjectHeadingComponent extends BaseComponent {

  @Prop() private id: any;

  private projectLoading: boolean = false;
  private editedProjectItem?: Project|null = null;

  protected mounted() {
    this.getProject(this.id);
  }

  private getProject(id: string) {
    this.projectLoading = true;
    getProject(id, true).then((response) => {
      this.editedProjectItem = response;
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.projectLoading = false;
    });
  }
}
